import { Organisation, User, TAllActionKeys } from '@otso/auth-wrapper';

import { AdminViewUser, SuperAdminViewUser } from 'types';
import { authAPI, getAPIErrMsg } from './helpers';

export const getAdminViewUser = async (userId: string): Promise<AdminViewUser> => {
  const res: { data: AdminViewUser } = await authAPI.get(`/users/${userId}`);
  return res.data;
};

export const addUserToCurrentOrg = async (userData: Partial<AdminViewUser>): Promise<string> => {
  const res: { data: { id: string } } = await authAPI.post('/users', userData);
  return res.data.id;
};

export const updateCurrentOrgUserPermissions = async (
  userId: string,
  userActions: { [key in TAllActionKeys]: boolean }
): Promise<void> => {
  return authAPI.put(`/users/${userId}`, { actions: userActions });
};

export const removeUserFromCurrentOrg = async (userId: string): Promise<void> => {
  return authAPI.delete(`/users/${userId}`);
};

export const updateMyDetails = async (firstName: string, lastName: string): Promise<void> => {
  return authAPI.put(`/users/me`, { firstName, lastName });
};

export const updateMyTimezone = async (newTimezone: string): Promise<void> => {
  return authAPI.put(`/users/me/timezone`, { timezone: newTimezone });
};

// Super Admin
export const createOrganisation = async (newOrg: Partial<Organisation>): Promise<string> => {
  const res: { data: { orgSlug: string } } = await authAPI.post(
    '/superadmin/organisations',
    newOrg
  );
  return res.data.orgSlug;
};

export const addUserToOrg = async (
  userData: {
    email: string;
    firstName: string;
    lastName: string;
    actions: { [key in TAllActionKeys]: boolean };
  },
  orgSlug: string
): Promise<string> => {
  const res: { data: { id: string } } = await authAPI.post(
    `/superadmin/organisations/${orgSlug}/users`,
    userData
  );
  return res.data.id;
};

export const updateOrgUserRoles = async (
  userId: string,
  orgSlug: string,
  actions: { [key in TAllActionKeys]: boolean }
): Promise<void> => {
  return authAPI.put(`/superadmin/organisations/${orgSlug}/users/${userId}`, actions);
};

export const removeUserFromOrg = async (userId: string, orgSlug: string): Promise<void> => {
  return authAPI.delete(`/superadmin/organisations/${orgSlug}/users/${userId}`);
};

export const getSuperAdminViewUser = async (userId: string): Promise<SuperAdminViewUser> => {
  const res: { data: SuperAdminViewUser } = await authAPI.get(`/superadmin/users/${userId}`);
  return res.data;
};

export const updateUserSuperAdminAccess = async (
  userId: string,
  isSuper: boolean
): Promise<void> => {
  return authAPI.put(`/superadmin/users/${userId}/isSuper`, { isSuper });
};

export const updateUserAccountStatus = async (userId: string, disabled: boolean): Promise<void> => {
  return authAPI.put(`/superadmin/users/${userId}/status`, { disabled });
};

export const deleteUserAccount = async (userId: string) => {
  return authAPI.delete(`/superadmin/users/${userId}`);
};

export const generateUserPasswordResetLink = async (userId: string): Promise<string> => {
  const res: { data: string } = await authAPI.post(`/superadmin/users/${userId}/password-reset`);
  return res.data;
};

export const getOrgUsers = async (orgSlug: string): Promise<AdminViewUser[]> => {
  const res: { data: AdminViewUser[] } = await authAPI.get(`/organisations/${orgSlug}/users`);
  return res.data;
};

export const getAllOrgs = async (): Promise<Organisation[]> => {
  const res: { data: Organisation[] } = await authAPI.get('/superadmin/organisations');
  return res.data;
};

export const getAllUsers = async (): Promise<User[]> => {
  const res: { data: User[] } = await authAPI.get('/superadmin/users');
  return res.data;
};

export const getOrg = async (orgSlug: string): Promise<Organisation | null> => {
  const res: { data: Organisation } = await authAPI.get(`/superadmin/organisations/${orgSlug}`);
  return res.data;
};

export const updateOrg = async (
  orgId: string,
  orgData: { logo?: string | null; timezone?: string | null }
): Promise<{ data: { success: boolean } }> => {
  return authAPI.put(`/superadmin/organisations/${orgId}`, orgData);
};

export { getAPIErrMsg };
