/** @jsxImportSource @emotion/react */
import React from 'react';
import { Route, Switch, Redirect, RouteProps } from 'react-router-dom';
import { useAuth, TAllActionValues, AuthActions } from '@otso/auth-wrapper';

import {
  Dashboard,
  Account,
  Users,
  EditUser,
  CreateUser,
  // Super Admin
  Organisations,
  CreateOrganisation,
  EditOrganisation,
  SuperAdminViewUsers,
  CreateUserInSuperAdmin,
  EditUserInSuperAdmin,
} from 'views';

interface CustomRouteProps extends RouteProps {
  action: TAllActionValues;
}

const AdminRoute: React.FC<CustomRouteProps> = ({ children, action, ...restProps }) => {
  const { user } = useAuth();

  return (
    <Route {...restProps}>
      {user?.actions?.[action] ? children : <Redirect to={{ pathname: '/' }} />}
    </Route>
  );
};

const SuperAdminRoute: React.FC<RouteProps> = ({ children, ...restProps }) => {
  const { user } = useAuth();
  return (
    <Route {...restProps}>{user?.isSuper ? children : <Redirect to={{ pathname: '/' }} />}</Route>
  );
};

export const Routes: React.FC = () => {
  return (
    <Switch>
      {/* Dashboard page */}
      <Route exact path="/dashboard" component={Dashboard} />

      <AdminRoute path={['/users']} action={AuthActions.manageOrganisationUsers}>
        <Switch>
          {/* Users page */}
          <Route exact path="/users" component={Users} />
          <Route exact path="/users/create" component={CreateUser} />
          <Route exact path="/users/:userId" component={EditUser} />
        </Switch>
      </AdminRoute>

      {/* Account page */}
      <Route exact path="/account/:page?" component={Account} />

      {/* Super Admin pages */}
      <SuperAdminRoute path="/superadmin">
        <Switch>
          <Route exact path="/superadmin/organisations/create" component={CreateOrganisation} />
          <Route exact path="/superadmin/organisations/:orgSlug" component={EditOrganisation} />
          <Route exact path="/superadmin/organisations" component={Organisations} />
          <Route exact path="/superadmin/users/create" component={CreateUserInSuperAdmin} />
          <Route exact path="/superadmin/users/:userId" component={EditUserInSuperAdmin} />
          <Route exact path="/superadmin/users" component={SuperAdminViewUsers} />
          <Redirect to="/superadmin/organisations" />
        </Switch>
      </SuperAdminRoute>

      {/* Redirect for unmatched routes */}
      <Redirect to="/dashboard" />
    </Switch>
  );
};
