/** @jsxImportSource @emotion/react */
import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Table, Input, Popconfirm, Space, Form, Modal, message } from 'antd';
import queryString from 'query-string';

import { preset } from 'styles';
import { SuperAdminViewUser, SuperAdminViewUserOrg } from 'types';
import { getUserDisplayName } from 'utils';
import { UserRoleFormItems } from 'components';
import { updateOrgUserRoles, removeUserFromOrg, getAPIErrMsg } from 'api';
import { UserRoleActionItems } from 'components/UserRoleActionItems';

type Props = {
  user: SuperAdminViewUser | null;
  setUser: React.Dispatch<React.SetStateAction<SuperAdminViewUser | null>>;
  isLoading?: boolean;
};

export const UserOrgsTable: React.FC<Props> = ({ user, setUser, isLoading = false }) => {
  const [searchText, setSearchText] = useState<string>('');
  const [editingOrg, setEditingOrg] = useState<SuperAdminViewUserOrg | null>(null);
  const [isSavingOrg, setIsSavingOrg] = useState<boolean>(false);

  const [userRoleForm] = Form.useForm();

  const onEditUserOrgRole = (selectedOrg: SuperAdminViewUserOrg) => {
    userRoleForm.setFieldsValue({
      actions: {},
    });
    setEditingOrg(selectedOrg);
  };

  const onEditUserOrgRoleCancel = () => {
    userRoleForm.resetFields();
    setEditingOrg(null);
  };

  const onUserOrgRolesSave = async () => {
    if (editingOrg && user) {
      setIsSavingOrg(true);
      try {
        const values = await userRoleForm.validateFields();

        await updateOrgUserRoles(user.id, editingOrg.id, values.actions);

        // Update user orgs state
        // setUser(
        //   (prevUser) =>
        //     prevUser && {
        //       ...prevUser,
        //       organisations: prevUser.organisations.map((org) => {
        //         if (org.id === editingOrg.id) {
        //           const { insight, data, annotator, ...restOrg } = org;
        //           return { ...restOrg };
        //         }
        //         return org;
        //       }),
        //     }
        // );
        message.success('User Roles Updated');
        onEditUserOrgRoleCancel();
      } catch (error) {
        // No error alert if it's antd form error
        if (!(error as any).errorFields) {
          message.error(getAPIErrMsg(error));
        }
      }
      setIsSavingOrg(false);
    }
  };

  const onRemoveUserOrg = async (orgSlug: string) => {
    if (user) {
      try {
        await removeUserFromOrg(user.id, orgSlug);
        // Update user orgs state
        setUser(
          (prevUser) =>
            prevUser && {
              ...prevUser,
              organisations: prevUser.organisations.filter((org) => org.id !== orgSlug),
            }
        );
        message.success('Organisation Removed');
      } catch (error) {
        message.error(getAPIErrMsg(error));
      }
    }
  };

  const filteredOrgs = useMemo(() => {
    const userOrgs = user?.organisations || [];
    if (!searchText) return userOrgs;
    return userOrgs.filter((org) => {
      return !![org.name, org.id].find((orgString) =>
        orgString.toLowerCase().includes(searchText.toLowerCase())
      );
    });
  }, [searchText, user?.organisations]);

  return (
    <>
      <Row justify="space-between" css={{ marginBottom: preset.spacing(3) }}>
        <Col span={10}>
          <Input.Search allowClear placeholder="Search" onSearch={setSearchText} />
        </Col>
        <Col>
          <Button type="primary">
            <Link
              to={queryString.stringifyUrl({
                url: '/superadmin/users/create',
                query: {
                  email: user?.email,
                  firstName: user?.firstName,
                  lastName: user?.lastName,
                  disabledOrgs: (user?.organisations || []).map((org) => org.id),
                },
              })}
            >
              Add Organisation
            </Link>
          </Button>
        </Col>
      </Row>

      <Table
        bordered
        loading={isLoading}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            render: (name, row) => <Link to={`/superadmin/organisations/${row.id}`}>{name}</Link>,
          },
          { title: 'Slug', dataIndex: 'id' },
          {
            title: 'Action',
            key: 'action',
            render: (val, row) => (
              <Space css={{ marginLeft: -preset.spacing(1) }}>
                <Button size="small" type="link" onClick={() => onEditUserOrgRole(row)}>
                  Edit Permissions
                </Button>
                <Popconfirm
                  title={`Are you sure you want to remove ${getUserDisplayName(user)} from ${
                    row.name
                  }?`}
                  okText="Remove"
                  okButtonProps={{ danger: true }}
                  onConfirm={() => onRemoveUserOrg(row.id)}
                >
                  <Button size="small" type="link" danger>
                    Remove
                  </Button>
                </Popconfirm>
              </Space>
            ),
          },
        ]}
        rowKey="id"
        dataSource={filteredOrgs}
        locale={{ emptyText: 'No Organisation Found' }}
        pagination={{
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
        }}
      />

      <Modal
        title="Edit Permissions"
        width={preset.modalWidth.md}
        open={!!editingOrg}
        onCancel={onEditUserOrgRoleCancel}
        okText="Save"
        okButtonProps={{ loading: isSavingOrg }}
        onOk={onUserOrgRolesSave}
      >
        <Form layout="vertical" form={userRoleForm}>
          <UserRoleActionItems />
        </Form>
      </Modal>
    </>
  );
};
