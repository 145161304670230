/** @jsxImportSource @emotion/react */
import { useState, useMemo, useEffect } from 'react';
import { Table, Row, Col, Input, Button, message } from 'antd';
import { Link } from 'react-router-dom';
import { User } from '@otso/auth-wrapper';
import moment from 'moment-timezone';

import { Container } from 'components';
import { preset } from 'styles';
import { getAllUsers, getAPIErrMsg } from 'api';
import { getUserDisplayName } from 'utils';

export const SuperAdminViewUsers = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    const fetchAllUsers = async () => {
      setIsLoading(true);
      try {
        const usersData = await getAllUsers();
        setUsers(usersData);
      } catch (error) {
        message.error(getAPIErrMsg(error));
      }
      setIsLoading(false);
    };
    fetchAllUsers();
  }, []);

  const filteredUsers = useMemo(() => {
    if (!searchText) return users;
    return users.filter((user) => {
      return !![user.email, user.firstName, user.lastName].find((userString) =>
        userString
          ? userString.toLowerCase().includes(searchText.toLowerCase())
          : false
      );
    });
  }, [searchText, users]);

  return (
    <Container paddingY={3}>
      <Row justify="space-between" css={{ marginBottom: preset.spacing(3) }}>
        <Col span={10}>
          <Input.Search
            allowClear
            placeholder="Search"
            onSearch={setSearchText}
          />
        </Col>
        <Col>
          <Button type="primary">
            <Link to="/superadmin/users/create">Create User</Link>
          </Button>
        </Col>
      </Row>
      <Table
        loading={isLoading}
        columns={[
          {
            title: 'Email',
            dataIndex: 'email',
            render: (email, row) => (
              <Link to={`/superadmin/users/${row.id}`}>{email}</Link>
            ),
          },
          {
            title: 'Name',
            key: 'name',
            render: (val, row) => getUserDisplayName(row),
          },
          {
            title: 'Created At',
            dataIndex: 'createdAt',
            render: (createdAt: User['createdAt']) =>
              createdAt && moment(createdAt).format('DD/MM/YYYY'),
          },
        ]}
        rowKey="id"
        dataSource={filteredUsers}
        locale={{ emptyText: 'No User Found' }}
        pagination={{
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
        }}
      />
    </Container>
  );
};
