/** @jsxImportSource @emotion/react */
import { INSIGHT_ACTIONS, DATA_ACTIONS, AUTH_ACTIONS } from '@otso/auth-wrapper';
import React from 'react';
import { Row, Col, Collapse } from 'antd';
import { preset } from 'styles';
import UserPermissionActionTable from 'components/UserPermissionActionTable';

export const UserRoleActionItems: React.FC = () => {
  const PRODUCTS: { [key: string]: string } = {
    insight: 'Otso Insight',
    data: 'Otso Data',
    auth: 'User Management & Authentication',
  };

  return (
    <Row gutter={preset.spacing(3)}>
      <Col span={16}>
        <Collapse>
          <Collapse.Panel header={PRODUCTS.insight} key="insight" style={{ borderBottom: 0 }}>
            <UserPermissionActionTable
              actions={INSIGHT_ACTIONS}
              displayHeader={false}
              displayActionKey={false}
            />
          </Collapse.Panel>
        </Collapse>
        <Collapse>
          <Collapse.Panel header={PRODUCTS.auth} key="auth" style={{ borderBottom: 0 }}>
            <UserPermissionActionTable
              actions={AUTH_ACTIONS}
              displayHeader={false}
              displayActionKey={false}
            />
          </Collapse.Panel>
        </Collapse>
        <Collapse>
          <Collapse.Panel header={PRODUCTS.data} key="data">
            <UserPermissionActionTable
              actions={DATA_ACTIONS}
              displayHeader={false}
              displayActionKey={false}
            />
          </Collapse.Panel>
        </Collapse>
      </Col>
    </Row>
  );
};
