// Import libraries
import ReactDOM from 'react-dom';
import { AuthWrapper, initAuth } from '@otso/auth-wrapper';

// Import Ant Design components
import enGB from 'antd/lib/locale/en_GB';
import { ConfigProvider } from 'antd';

import { Routes } from 'routes';
import { AppLayout } from 'components';

// Import antd styles
import 'antd/dist/antd.less';

initAuth({
  firebaseConfig: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  },
  authAPIHost: process.env.REACT_APP_AUTH_API_URL,
  authHost: process.env.REACT_APP_AUTH_CLIENT_URL,
});

const App = () => {
  return (
    <AuthWrapper logo="/logo/otso_mark-1.png" layout={false}>
      <AppLayout>
        <ConfigProvider locale={enGB}>
          <Routes />
        </ConfigProvider>
      </AppLayout>
    </AuthWrapper>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
