import axios, { AxiosError } from 'axios';

import { auth } from 'firebase-export';

const getUserToken = async (): Promise<string | null> => {
  const { currentUser } = await auth();
  const token = await currentUser?.getIdToken();
  return token || null;
};

const authAPI = axios.create({
  baseURL: process.env.REACT_APP_AUTH_API_URL,
  headers: { Accept: 'application/json' },
});

authAPI.interceptors.request.use(async (config) => {
  const idToken = await getUserToken();
  return idToken
    ? {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `JWT ${idToken}`,
        },
      }
    : config;
});

const getAPIErrMsg = (err: unknown, fallback = 'Internal Server Error'): string => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(err);
  }
  return (
    (
      err as AxiosError<{
        logId: string;
        name: string;
        message: string;
      }>
    ).response?.data?.message || fallback
  );
};

export { authAPI, getAPIErrMsg };
