/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';
import { Checkbox, Tag, Form, Tooltip } from 'antd';
import _ from 'lodash';
import { Action } from '@otso/auth-wrapper';

import { otsoGray } from 'styles/colors';
import { spacing } from 'styles/preset';
import { QuestionCircleOutlined } from '@ant-design/icons';

type Props = {
  actions: Action[];
  displayHeader?: boolean;
  displayActionKey?: boolean;
  disabled?: boolean;
  onPermissionItemChange?: any;
};

const UserPermissionActionTable: React.FC<Props> = ({
  actions,
  onPermissionItemChange,
  displayHeader = true,
  displayActionKey = true,
  disabled = false,
}) => {
  const groups = _.groupBy(actions, (group) => {
    return _.get(group, 'group', 'Other');
  });

  return (
    <div className="ant-table ant-table ant-table-bordered">
      <div className="ant-table-container">
        <div className="ant-table-content">
          <table>
            {displayHeader && (
              <thead className="ant-table-thead">
                <tr>
                  <th className="ant-table-cell">Action</th>
                  <th className="ant-table-cell">Key</th>
                </tr>
              </thead>
            )}
            <tbody className="ant-table-tbody">
              {Object.entries(groups).map(([groupName, groupActions]) => (
                <Fragment key={`g-${groupName}`}>
                  {Object.keys(groups).length > 1 && (
                    <tr>
                      <td
                        className="ant-table-cell"
                        colSpan={3}
                        style={{
                          textAlign: 'left',
                          fontWeight: 'bold',
                          paddingTop: spacing(1),
                          paddingBottom: spacing(1),
                          paddingLeft: spacing(3),
                          backgroundColor: otsoGray,
                        }}
                      >
                        {groupName}
                      </td>
                    </tr>
                  )}
                  {groupActions.map((action: Action) => (
                    <ActionTableRow
                      key={action.actionKey}
                      action={action}
                      displayActionKey={displayActionKey}
                      onPermissionItemChange={onPermissionItemChange}
                      disabled={disabled}
                    />
                  ))}
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

type ActionTableRowProps = {
  action: Action;
  displayActionKey: boolean;
  onPermissionItemChange?: any;
  disabled?: boolean;
};

const ActionTableRow = ({
  action,
  displayActionKey,
  onPermissionItemChange,
  disabled,
}: ActionTableRowProps) => {
  return (
    <tr className="ant-table-row ant-table-row-level-0" key={`a-${action.actionKey}`}>
      <td
        className="ant-table-cell"
        style={{
          paddingLeft: spacing(3),
        }}
      >
        {action.shortDescription || action.actionKey}
        {'  '}
        <Tooltip title={action.longDescription}>
          <QuestionCircleOutlined />
        </Tooltip>
      </td>
      <td
        className="ant-table-cell"
        style={{ textAlign: 'center' }}
        key={`a-${action.actionKey}`}
        width="100px"
      >
        <Form.Item
          name={['actions', action.actionKey]}
          valuePropName="checked"
          style={{ marginBottom: 0 }}
        >
          <Checkbox
            disabled={disabled}
            onChange={(e) => onPermissionItemChange && onPermissionItemChange(e)}
          />
        </Form.Item>
      </td>
      {displayActionKey && (
        <td className="ant-table-cell" style={{ width: '100px' }}>
          <Tag>{action.actionKey}</Tag>
        </td>
      )}
    </tr>
  );
};

export default UserPermissionActionTable;
