// Import Ant Design components
import {
  DatabaseOutlined,
  FormOutlined,
  AreaChartOutlined,
  TranslationOutlined,
} from '@ant-design/icons';

const otsoData = {
  name: 'otso Data',
  host: process.env.REACT_APP_DATA_CLIENT_URL,
  icon: <DatabaseOutlined />,
};

const otsoAnnotator = {
  name: 'otso Annotator',
  host: process.env.REACT_APP_ANNOTATOR_CLIENT_URL,
  icon: <FormOutlined />,
};

const otsoNLP = {
  name: 'otso NLP',
  host: process.env.REACT_APP_DEPLOY_CLIENT_URL,
  icon: <TranslationOutlined />,
};

const otsoInsight = {
  name: 'otso Insight',
  host: process.env.REACT_APP_INSIGHT_CLIENT_URL,
  icon: <AreaChartOutlined />,
};

// Prepare a list of products
export const products = [otsoData, otsoAnnotator, otsoNLP, otsoInsight];

// Prepare a list of categories and products
export const categories = [
  {
    name: 'Data Management and Processing',
    products: [otsoData],
  },
  // {
  //   name: 'Text Annotations',
  //   products: [otsoAnnotator],
  // },
  // {
  //   name: 'Machine Learning',
  //   products: [otsoNLP],
  // },
  {
    name: 'Data Analytics',
    products: [otsoInsight],
  },
];
